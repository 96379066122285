import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import UploadVideoStep from './subpages/UploadVideoStep';
import PreprocessingStep from './subpages/PreprocessingStep';
import GpsExtractionStep from './subpages/GpsExtractionStep';
import RunInferenceStep from './subpages/RunInferenceStep';
import newlogo from "../../../../../../assets/bg/roadGaugeLogo2.png";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import UploadIcon from '@mui/icons-material/Upload';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import UpdateIcon from '@mui/icons-material/Update';
import EditIcon from '@mui/icons-material/Edit';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { connect } from "react-redux";
import { get } from "lodash";
import UploadVideoStepVisualizer from './subpages/UploadVideoStepVisualizer';
import GpsExtractionStepVisualizer from './subpages/GpsExtractionStepVisualizer';
import VideoSelectorStep from './subpages/VideoSelectorStep';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import EditGpsMap from './subpages/EditGpsMap';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import { ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import UpdateDetailsStep from './subpages/UpdateDetailsStep';
import DateParser from '../../../../../../helpers/DateParser';
import RoadConditonIndex from '../../../rci/RoadConditonIndex';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import SmartUtils from '../../../../../../helpers/SmartUtils';

const steps = ['Upload videos', 'Preprocess data', 'Extract GPS', 'Edit GPS', 'Confirm details', 'Setup project'];
const stepsWithCCI = ['Upload videos', 'Preprocess data', 'Extract GPS', 'Edit GPS', 'Video selector', 'Confirm details', 'Setup project'];
const mutlipleVideoSteps = ['Upload videos', 'Preprocess data', 'Extract GPS', 'Edit GPS', 'Confirm details', 'Setup project'];
const mutlipleVideoSelectorSteps = ['Upload videos', 'Preprocess data', 'Extract GPS', 'Edit GPS', 'Confirm details', 'Video selector', 'Setup project'];
const SetupProject = (props) => {
    const [disableHeader, setDisableHeader] = useState(false);
    const section1 = disableHeader ? 0 : 20;
    const section2 = disableHeader ? 100 : 80;

    const section1_1 = 10;
    const section1_2 = 80;
    const section1_3 = 10;

    const section2_1 = 80;
    // const section2_2 = 10;
    const titleStyle = { fontWeight: 'bold', fontSize: '14px' }

    const { drProjectData, proid, analysisRequests, filteredGpsData360, originalProjectGpsDataList, gpsData } = props;
    const { status, projectType, projectCreditJar, projectTitle, dateCreated } = drProjectData;
    const [isEdit, setIsEdit] = useState(false);
    const [activeStep, setActiveStep] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const date = dateCreated ? DateParser(dateCreated, 0) : DateParser(dateCreated, 2)

    const redColorStatus = [-1, -3, -4, -5, -14];
    // const multipleVideoSupportStatus = [1, 4, 5];
    const multipleVideoSupportStatus = [5];
    // const multipleVideoSupportStatusWithCCI = [1, 4];
    const statusToStep = {
        "0": 0,

        "1": 0,
        "-1": 0,

        "2": 1,
        "-2": 0,

        "3": 1,
        "-3": 2,

        "4": 2,
        "-4": 2,

        "5": projectCreditJar ? projectType === 2 ? 5 : projectType === 5 ? 6 : 6 : 3,
        "-5": 2,

        "6": projectType === 2 ? 5 : projectType === 5 ? 6 : 6,
        "-6": projectType === 2 ? 5 : projectType === 5 ? 6 : 6,

        "7": projectType === 2 ? 5 : projectType === 5 ? 6 : 6,
        "-7": projectType === 2 ? 5 : projectType === 5 ? 6 : 6,

        "-14": 2
    }

    const projectTypeText = {
        0: "Assets",
        1: "3D Scan",
        2: "Video",
        3: "Safety",
        4: "Advanced",
        5: "Basic"
    };

    const typeText = projectTypeText[projectType];

    useEffect(() => {
        if (`${status}` in statusToStep) {
            setActiveStep(statusToStep[`${status}`]);
        }

        if (drProjectData.isProjectUpdated) {
            switch (drProjectData.projectType) {
                case 2:
                    setActiveStep(5);
                    break;
                case 0:
                    setActiveStep(5);
                    break;
                default:
                    setActiveStep(6);
            }
            return;
        }

        // if (drProjectData.isroadConditionIndexUpdated && (drProjectData.projectType === 1 || drProjectData.projectType === 4)) {
        //     setActiveStep(6);
        //     return;
        // }

        if (drProjectData.isVideoSelectionDone && (drProjectData.projectType === 1 || drProjectData.projectType === 4 || drProjectData.projectType === 5)) {
            setActiveStep(5);
            return;
        }

        if (drProjectData.isGpsEditDone) {
            setActiveStep(4);
            return;
        }
    }, [status, drProjectData]);


    const handleDetailsOpen = () => {
        setOpenDetails(true);
    };

    const handleDetailsClose = () => {
        setOpenDetails(false);
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    redColorStatus.includes(status) ?
                        'linear-gradient( 0deg, #C70202 0%, #C70202 50%, #C70202 100%)'
                        :
                        'linear-gradient( 0deg, #041256 0%, #041256 50%, #041256 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 0deg, #041256 0%, #041256 50%, #041256 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: '#808080',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                redColorStatus.includes(status) ?
                    'linear-gradient( 0deg, #C70202 0%, #C70202 50%, #C70202 100%)' :
                    'linear-gradient( 0deg, #041256 0%, #041256 50%, #041256 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 0deg, #041256 0%, #041256 50%, #041256 100%)',
        }),
    }));
    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <UploadIcon />,
            2: <HourglassTopIcon />,
            3: <ShareLocationIcon />,
            4: <EditLocationIcon />,
            5: <SelectAllIcon />,
            6: <UpdateIcon />,
            7: <EditIcon />,
            8: <PlayArrowIcon />,
        };
        const iconsWithCCI = {
            1: <UploadIcon />,
            2: <HourglassTopIcon />,
            3: <ShareLocationIcon />,
            4: <EditLocationIcon />,
            5: <SelectAllIcon />,
            // 6: <AddRoadIcon />,
            6: <UpdateIcon />,
            7: <EditIcon />,
            8: <PlayArrowIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {SmartUtils.supportCCIProject(projectType) ? iconsWithCCI[String(props.icon)] : icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };


    const components = projectType === 2 ?
        [
            <UploadVideoStepVisualizer proid={proid} />,
            <PreprocessingStep />,
            <GpsExtractionStep proid={proid} setActiveStep={setActiveStep} originalProjectGpsDataList={filteredGpsData360} />,
            // <GpsExtractionStepVisualizer proid={proid} setActiveStep={setActiveStep} />,
            <EditGpsMap setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} allCoordinates={filteredGpsData360} projectType={projectType} drProjectData={drProjectData} />,
            <UpdateDetailsStep proid={proid} drProjectData={drProjectData} projectType={projectType} setActiveStep={setActiveStep} />,
            <RunInferenceStep setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={filteredGpsData360} />

        ] :
        SmartUtils.supportCCIProject(projectType) ?
            [
                <UploadVideoStepVisualizer proid={proid} />,
                <PreprocessingStep />,
                <GpsExtractionStep proid={proid} setActiveStep={setActiveStep} originalProjectGpsDataList={originalProjectGpsDataList} />,
                <EditGpsMap setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} allCoordinates={originalProjectGpsDataList} projectType={projectType} drProjectData={drProjectData} />,
                <VideoSelectorStep proid={proid} drProjectData={drProjectData} gpsData={gpsData} setActiveStep={setActiveStep} />,
                // <RoadConditonIndex showSkip={true} setActiveStep={setActiveStep} proid={proid} />,
                <UpdateDetailsStep proid={proid} drProjectData={drProjectData} projectType={projectType} setActiveStep={setActiveStep} />,
                <RunInferenceStep setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
            ]
            :
            multipleVideoSupportStatus.includes(projectType)
                ?
                [
                    <UploadVideoStepVisualizer proid={proid} />,
                    <PreprocessingStep />,
                    <GpsExtractionStep proid={proid} setActiveStep={setActiveStep} originalProjectGpsDataList={originalProjectGpsDataList} />,
                    <EditGpsMap setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} allCoordinates={originalProjectGpsDataList} projectType={projectType} drProjectData={drProjectData} />,
                    <VideoSelectorStep proid={proid} drProjectData={drProjectData} gpsData={gpsData} setActiveStep={setActiveStep} />,
                    <UpdateDetailsStep proid={proid} drProjectData={drProjectData} projectType={projectType} setActiveStep={setActiveStep} />,
                    <RunInferenceStep setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
                ]
                :
                [
                    <UploadVideoStep proid={proid} />,
                    <PreprocessingStep />,
                    <GpsExtractionStep proid={proid} setActiveStep={setActiveStep} />,
                    <EditGpsMap setDisableHeader={setDisableHeader} setActiveStep={setActiveStep} proid={proid} allCoordinates={originalProjectGpsDataList} projectType={projectType} drProjectData={drProjectData} />,
                    // <VideoSelectorStep proid={proid} drProjectData={drProjectData} gpsData={gpsData} setActiveStep={setActiveStep} />,
                    <UpdateDetailsStep proid={proid} drProjectData={drProjectData} projectType={projectType} setActiveStep={setActiveStep} />,
                    <RunInferenceStep setActiveStep={setActiveStep} proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
                ];

    return (
        <div style={{ width: '', height: `100vh`, backgroundColor: '' }}>
            <div style={{ width: '', height: `${section1}vh`, backgroundColor: 'transparent', display: disableHeader ? 'none' : 'flex' }}>
                <Grid container spacing={0}>
                    <Grid item xs={1} sx={{ width: `${section1_1}%`, height: `${section1}vh`, backgroundColor: 'transparent', display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <Box>
                                <img
                                    style={{}}
                                    src={newlogo}
                                    alt="logo"
                                    width="80px"
                                    height="auto"
                                />
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={9} sx={{ width: `${section1_2}%`, height: `${section1}vh`, backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', paddingInline: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} sx={{ width: '100%' }}>
                            {projectType === 2 ?
                                mutlipleVideoSteps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                )) :
                                SmartUtils.supportCCIProject(projectType) ?
                                    stepsWithCCI.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))
                                    :
                                    multipleVideoSupportStatus.includes(projectType) ?
                                        mutlipleVideoSelectorSteps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </Step>
                                        ))
                                        :
                                        steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </Step>
                                        ))
                            }
                        </Stepper>
                    </Grid>

                    <Grid item xs={1} sx={{ width: `${section1_3}%`, height: `${section1}vh`, backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SimpleDialogBox
                            open={openDetails}
                            onClose={handleDetailsClose}
                            title="Project details "
                            width={600}
                            bodyWidget={
                                <>
                                    <ListItem
                                        disablePadding
                                        sx={{ paddingBlock: '5px' }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="p" sx={titleStyle}>Project title:</Typography>}
                                            secondary={`${projectTitle}`}
                                        />

                                    </ListItem>

                                    <ListItem
                                        disablePadding
                                        sx={{ paddingBlock: '5px' }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="p" sx={titleStyle}>Project type:</Typography>}
                                            secondary={typeText}
                                        />

                                    </ListItem>

                                    <ListItem
                                        disablePadding
                                        sx={{ paddingBlock: '5px' }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="p" sx={titleStyle}>Created on:</Typography>}
                                            secondary={`${date} — ${dateCreated ? DateParser(dateCreated, 1) : DateParser(dateCreated, 2)}`}
                                        />
                                    </ListItem>

                                </>
                            }
                        />

                        <Tooltip title={'Project details'} arrow placement='bottom'>
                            <IconButton onClick={handleDetailsOpen}>
                                <InfoIcon scale={1} sx={{ fontSize: 32, color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={1} sx={{ width: `${section1_3}%`, height: `${section1}vh`, backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Link to="/home/dashboard" style={{ textDecoration: "none", color: "", cursor: 'pointer' }}>
                            <IconButton title='Go back to home'>
                                <ClearIcon sx={{ fontSize: '32px' }} />
                            </IconButton>
                        </Link>
                    </Grid>

                </Grid>
            </div>

            <div style={{ width: '', height: `${section2}vh`, backgroundColor: 'transparent' }}>
                <Box sx={{ width: '100%' }}>
                    <div style={{ height: `${section2_1}vh`, backgroundColor: 'transparent' }}>{components[activeStep]}</div>
                </Box>
            </div>
        </div>
    );

}
const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    const projectData = get(
        state.firestore.data,
        `DrProject.${proid}`
    )
    return {
        userData: state.firebase.profile,
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
        gpsData: get(state.firebase.data, `GpsData.${projectData?.proid}.gps`),

    };
};
export default connect(mapStateToProps)(SetupProject);

