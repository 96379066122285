import React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import RoadConditonIndex from '../../rci/RoadConditonIndex';
import SmartUtils from '../../../../../helpers/SmartUtils';

function DistressFilter({ proid, drProjectData, filteredGpsData, isFilterOpen, selectedDefects, handleGpsDataFilterChange, getSelectedDefectNames, defectNames, conditionMethod, setConditionMethod, sectionLength, setSectionLength, }) {
    const availablecondition = drProjectData.conditionIndex;
    const handleSectionChange = (event) => {
        setSectionLength(event.target.value);
    };

    const handleConditionMethodChange = (event) => {
        const method = event.target.value;
        setConditionMethod(method);
        localStorage.setItem('conditionMethod', method);
    };
    const conditionIndices = {
        "Custom Condition Index": 2,
        "Pavement Condition Index": 1,
        "Road Condition Index": 0,
        "None": -1,
    };
    ;
    return (
        <div>

            <FormControl size="small" sx={{ width: "100%" }}>
                {/* DONOT REMOVE : ABDULLAH */}
                {/* {isFilterOpen ? <></> : <InputLabel htmlFor="defect-select">Defect type</InputLabel>} */}

                <InputLabel htmlFor="defect-select">Defect type</InputLabel>
                <Select
                    size="small"
                    sx={{
                        width: '100%',
                        backgroundColor: "transparent",
                        color: "#000000",
                        textTransform: "initial",
                        paddingInline: "8px",
                        overflow: "auto",
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: "#000000",
                        },
                    }}
                    value={selectedDefects}
                    onChange={handleGpsDataFilterChange}
                    multiple
                    label="Defect type"
                    inputProps={{
                        name: 'defect',
                        id: 'defect-select',
                    }}
                    renderValue={(selected) => getSelectedDefectNames().join(', ')}
                >
                    {defectNames.map((defectName) => (
                        <MenuItem key={defectName.defectId} value={defectName.defectId}>
                            <Checkbox checked={selectedDefects.indexOf(defectName.defectId) > -1} />
                            <ListItemText primary={defectName.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl size="small" sx={{ backgroundColor: "transparent", width: "100%", marginTop: 3 }}>
                <InputLabel id="section-length-label">Section Length</InputLabel>
                <Select
                    labelId="section-length-label"
                    id="section-length"
                    value={sectionLength}
                    label="Section Length"
                    onChange={handleSectionChange}
                >
                    <MenuItem value={10}>10</MenuItem>
                </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: "100%", marginTop: 3 }}>
                <InputLabel id="condition-method-label">Condition evaluation method</InputLabel>
                {drProjectData.projectType === 4 ? (
                    availablecondition ? (
                        <Select
                            labelId="condition-method-label"
                            id="condition-method"
                            value={conditionMethod}
                            label="Condition Evaluation Method"
                            onChange={handleConditionMethodChange}
                        >
                            {Object.entries(conditionIndices).map(([key, value]) => (
                                availablecondition.includes(value) && (
                                    <MenuItem key={value} value={value}>
                                        {key}
                                    </MenuItem>
                                )
                            ))}
                        </Select>
                    ) : (

                        <Select
                            labelId="condition-method-label"
                            id="condition-method"
                            value={conditionMethod}
                            label="Condition evaluation method"
                            onChange={handleConditionMethodChange}
                        >
                            <MenuItem value={1}>Pavement Condition Index</MenuItem>
                            <MenuItem value={2}>Custom Condition Index</MenuItem>
                            <MenuItem value={-1}>None</MenuItem>
                        </Select>
                    )

                ) : (
                    availablecondition ? (
                        <Select
                            labelId="condition-method-label"
                            id="condition-method"
                            value={conditionMethod}
                            label="Condition Evaluation Method"
                            onChange={handleConditionMethodChange}
                        >
                            {Object.entries(conditionIndices).map(([key, value]) => (
                                availablecondition.includes(value) && (
                                    <MenuItem key={value} value={value}>
                                        {key}
                                    </MenuItem>
                                )
                            ))}
                        </Select>
                    ) : (
                        <Select
                            labelId="condition-method-label"
                            id="condition-method"
                            value={conditionMethod}
                            label="Condition evaluation method"
                            onChange={handleConditionMethodChange}
                        >
                            <MenuItem value={0}>Road Condition Index</MenuItem>
                            <MenuItem value={1}>Pavement Condition Index</MenuItem>
                            <MenuItem value={2}>Custom Condition Index</MenuItem>
                            <MenuItem value={-1}>None</MenuItem>
                        </Select>
                    )
                )}
            </FormControl>

            {SmartUtils.supportCCIProject(drProjectData.projectType) && conditionMethod === 2 ? <RoadConditonIndex proid={proid} height="100%" /> : <></>}

        </div>
    );
}

export default DistressFilter;