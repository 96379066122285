import { useEffect, useState } from "react";
import {
    Player, BigPlayButton, ControlBar, PlayToggle, PlaybackRateMenuButton, Shortcut,
    VolumeMenuButton, ReplayControl, ForwardControl, CurrentTimeDisplay, FullscreenToggle,
} from 'video-react';
import POSTER from '../../../../../../assets/bg/POSTER1.png'
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { Dialog, Menu, MenuItem } from "@mui/material";
import AddNewPotholeFeature from "./AddNewPotholeFeature";

const PotholeVideoPlayer = ({ projectPredictions, onCurrentTimeChange, clearAddDefectErrorAndMsg, addDefectDoneAndSent, showAddDefectLoader, addDefectMessage, addBasicImagingDefect, basicImagingDataInfo = [], proid, allPolyPoints, openAddNewAssetDialog, setOpenAddNewAssetDialog, handleCloseAddNewAssetDialog, originalVideoHeight, originalVideoWidth, isAddingAsset = false, shouldOffset = true, startTime = 0, link, playerRef, pinned, height, control = true, handleNextFrame, handlePrevFrame, fps = 24, disableControlsFully = false }) => {
    // //////console.log(isAddingAsset, "asset")
    const [seekSeconds, setSeekSeconds] = useState(0);
    const [rate, setRate] = useState(1);
    const [videoPlayerState, setVideoPlayerState] = useState(null)

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.video.playbackRate = 0.1; // Set to your desired initial playback rate
        }
    }, []);
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        if (!videoPlayerState?.isActive) {
            playerRef.current?.play()
            playerRef.current?.pause()
        }
    }, videoPlayerState)

    useEffect(() => {
        // //////console.log(currentTime)
        onCurrentTimeChange(videoPlayerState?.currentTime)
    }, [videoPlayerState])
    const handleSubmit = (e) => {
        e.preventDefault();

        // //////console.log(seekSeconds)
        playerRef.current.seek(seekSeconds)
        playerRef.current.pause()
        playerRef.current.playbackRate = rate
    }

    const handleSeekChange = (e) => {
        setSeekSeconds(e.target.value)
    }

    const handleRateChange = (e) => {
        setRate(e.target.value)
    }

    const centerMedia = {
        margin: 'auto',
        width: '60%',
        border: '3px solid #73AD21',
        padding: '10px',
    }
    // //////console.log(videoPlayerState?.currentTime)
    const [openFullscreen, setOpenFullscreen] = useState(false);
    const handleClickOpen = () => {
        setOpenFullscreen(true);
        handleMenuClose()
    };
    const handleClickClose = () => {
        setOpenFullscreen(false);
    };
    const [menuOpen, setMenuOpen] = useState(null);
    const open = Boolean(menuOpen);
    const handleMenuClick = (event) => {
        setMenuOpen(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuOpen(null);
    };
    // Math.floor(fps * videoPlayerState?.currentTime) ?? 0
    // //////console.log(videoPlayerState?.currentTime, 'starttime')
    return (
        <>
            <SimpleDialogBox
                open={openFullscreen}
                onClose={handleClickClose}
                title="Full screen"
                width={1000}
                minWidth={800}
                bodyWidget={
                    <></>
                }
            />
            <Dialog
                fullScreen
                open={openAddNewAssetDialog}
                onClose={handleCloseAddNewAssetDialog}
            >
                <AddNewPotholeFeature projectPredictions={projectPredictions} clearAddDefectErrorAndMsg={clearAddDefectErrorAndMsg} addDefectMessage={addDefectMessage} showAddDefectLoader={showAddDefectLoader} addDefectDoneAndSent={addDefectDoneAndSent} addBasicImagingDefect={addBasicImagingDefect} basicImagingDataInfo={basicImagingDataInfo} proid={proid} allPolyPoints={allPolyPoints} handleClose={handleCloseAddNewAssetDialog} startTime={videoPlayerState?.currentTime} fps={fps} playerRef={playerRef} link={link} originalVideoHeight={originalVideoHeight}
                    originalVideoWidth={originalVideoWidth} />
            </Dialog>

            <Menu
                id="video-menu"
                menuOpen={menuOpen}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'video-div',
                }}
            >
                <MenuItem onClick={handleClickOpen}>Full screen mode</MenuItem>
            </Menu>

            <div
                id="video-div"
                // aria-controls={open ? 'video-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onContextMenu={e => {
                    e.preventDefault()
                    // handleMenuClick(e)
                }}
            >
                <Player startTime={shouldOffset ? (startTime + (1 / fps)) : startTime}
                    ref={playerRef} preload="auto" autoPlay={false} muted={true} src={link} poster={POSTER} fluid={false} height={height} width="auto" playsInline={false}>
                    <Shortcut clickable={true} />
                    <BigPlayButton position="center" />
                    {/* <PlayToggle/> */}
                    <ControlBar className="video-styler" autoHide={false} disableDefaultControls={control} disableCompletely={disableControlsFully}>
                        <span style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Previous frame" role="button" onClick={handlePrevFrame}>{!control ? '<' : ''}</span>
                        <span style={{ color: "white", padding: '10px', }}>{videoPlayerState === null ? '' : 'Frame: ' + Math.floor(fps * videoPlayerState.currentTime)}</span>
                        <span style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Next frame" role="button" onClick={handleNextFrame}>{!control ? '>' : ''}</span>
                        {control ? <></> : <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />}
                        <VolumeMenuButton disabled />
                    </ControlBar>
                </Player>

            </div>
        </>
    );
}

export default PotholeVideoPlayer;