import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tooltip, Grid, CircularProgress, Button, FormControl, Box, IconButton, Typography, MenuItem, TextField, InputLabel, Select, Checkbox, ListItemText } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { connect } from "react-redux";
import { get } from "lodash";
import SimpleDialogBox from "../../cidgets/SimpleDialogBox";
import { addCategory } from "../../../store/actions/projectActions";
import DateParser from "../../../../src/helpers/DateParser";
import { useAccessControlContext } from "../../../contexts/AccessControlProvider";


const TopbarVisualizer = ({ proid, drProjectData, project, selectedDistance, setSelectedDistance, setCategoryDetails, selectedCategories, setSelectedCategories, addCategory }) => {
  const navigate = useNavigate();
  const { permissions } = useAccessControlContext();
  const { canAddTagType360 } = permissions;
  const { showAddCategoryArchiveLoader, isAddCategoryModalClose } = project;
  const { projectTitle, dateCreated, categories } = drProjectData;

  const date = dateCreated ? DateParser(dateCreated, 0) : DateParser(dateCreated, 2);

  const categoryNames = useMemo(() => {
    const names = categories ? Object.values(categories).map(category => ({
      categoryId: category.categoryId,
      name: category.name
    })) : [];
    const updatedNames = [{ categoryId: "All", name: "All" }, ...names];
    updatedNames.sort((a, b) => a.name.localeCompare(b.name));
    return updatedNames;
  }, [categories]);

  const [openDialog, setOpenDialog] = useState(false);
  const [textInput, setTextInput] = useState("");


  useEffect(() => {
    if (isAddCategoryModalClose) {
      setOpenDialog(false);
      setTextInput("");
    }
  }, [isAddCategoryModalClose]);

  useEffect(() => {
    if (selectedCategories.includes("All")) {
      const allCategoryIds = categories ? Object.values(categories).map(category => category.categoryId) : [];
      const allIds = ["All", ...allCategoryIds];
      const areSameArrays = selectedCategories.length === allIds.length && selectedCategories.every((value, index) => value === allIds[index]);
      if (!areSameArrays) {
        setSelectedCategories(allIds);
      }
    }
  }, [selectedCategories, categories]);

  const handleAddCategory = (() => {
    if (textInput.trim() === "") {
      alert("Please enter a category name.");
    } else if (textInput.length > 40) {
      alert("Category name should not exceed 40 characters.");
    } else {
      addCategory(proid, textInput);
    }
  });

  const handleFilterChange = ((event) => {
    const selectedCategoryIds = event.target.value;
    setSelectedCategories(selectedCategoryIds);

    const selectedCat = selectedCategoryIds.includes('All') ? categoryNames : selectedCategoryIds.map(eachCategoryId =>
      categoryNames.find(eachCategory => eachCategory.categoryId === eachCategoryId)
    );
    setCategoryDetails(selectedCat.length > 0 ? selectedCat : []);
  });

  const getSelectedCategoryNames = (() => {
    return selectedCategories.map(categoryId => {
      const selectedCategory = categoryNames.find(cat => cat.categoryId === categoryId);
      return selectedCategory ? selectedCategory.name : '';
    }).join(', ');
  });

  const options = drProjectData.distanceInterval ? [{ value: drProjectData.distanceInterval, label: `${drProjectData.distanceInterval} meters` },] : [{ value: 2, label: '2 meters' },];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
          <Card sx={{ borderRadius: "50px", backgroundColor: "#fff", "&:hover": { backgroundColor: "#fafafa" } }} elevation={5}>
            <IconButton aria-label="roundedBtn" style={{ backgroundColor: "transparent" }} onClick={() => navigate('/home')}>
              <KeyboardArrowLeft fontSize="large" sx={{ color: "#1E1E1E" }} />
            </IconButton>
          </Card>
        </Grid>
        <Grid item xs={10} sm={10} md={11} sx={{ backgroundColor: "transparent", borderRadius: "15px" }}>
          <Card elevation={4} sx={{ backgroundColor: "#FAFAFA", borderRadius: "15px" }}>
            <div style={{ height: "100%", width: "100%", backgroundColor: "transparent", display: "flex", alignItems: "center", padding: "8px" }}>
              <Box sx={{ display: { xs: "none", md: "flex" }, width: { xs: "0%", md: "50%" }, height: "100%", alignItems: "center" }}>
                <Tooltip title={`${projectTitle} — ${date}`} placement="bottom">
                  <Typography variant="body1" sx={{ paddingLeft: "15px", cursor: "pointer", display: { xs: "none", md: "flex" }, fontSize: "17px" }}>
                    {projectTitle ? (
                      projectTitle.length > 10 ? (
                        <span>{projectTitle.slice(0, 10)}... — <span>{date}</span></span>
                      ) : (
                        <span>{projectTitle} — <span>{date}</span></span>
                      )
                    ) : ""}
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "70%" }, display: "flex", alignItems: "center", justifyContent: { xs: "space-evenly", md: "end" }, height: "100%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Box sx={{ border: "1px solid #000", borderRadius: "4px", marginRight: '5px', display: { xs: "none", lg: "flex" } }}>
                      <Button
                        disabled={!canAddTagType360}
                        variant="text"
                        size="small"
                        sx={{ backgroundColor: "transparent", color: "#000", textTransform: "initial", fontSize: "15px", paddingInline: "8px", "&:hover": { backgroundColor: "transparent", color: "#000" } }}
                        onClick={() => setOpenDialog(true)}
                      >
                        Define type
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <FormControl size="small" sx={{ width: "100%" }}>
                      <InputLabel htmlFor="category-select">Tag filter</InputLabel>
                      <Select
                        size="small"
                        sx={{ width: '100%', backgroundColor: "transparent", color: "#000", textTransform: "initial", paddingInline: "8px", overflow: "auto", "&:hover": { backgroundColor: "transparent", color: "#000" } }}
                        value={selectedCategories}
                        onChange={handleFilterChange}
                        multiple
                        label="Tag filter"
                        inputProps={{ name: 'category', id: 'category-select' }}
                        renderValue={getSelectedCategoryNames}
                      >
                        {categoryNames.map(categoryName => (
                          <MenuItem key={categoryName.categoryId} value={categoryName.categoryId} disabled={selectedCategories.includes("All") && categoryName.categoryId !== "All"}>
                            <Checkbox checked={selectedCategories.indexOf(categoryName.categoryId) > -1} />
                            <ListItemText primary={categoryName.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <FormControl size="small" sx={{ backgroundColor: "transparent", width: "100%", marginLeft: '5px' }}>
                      <InputLabel htmlFor="distance-interval">Distance interval</InputLabel>
                      <Select
                        size="small"
                        sx={{ width: '100%', backgroundColor: "transparent", color: "#000", textTransform: "initial", paddingInline: "8px", "&:hover": { backgroundColor: "transparent", color: "#000" } }}
                        value={selectedDistance}
                        label="Distance interval"
                        onChange={(e) => setSelectedDistance(parseInt(e.target.value))}
                      >
                        {options.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <SimpleDialogBox
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                title="Define tag type"
                cancelText="Cancel"
                bodyWidget={
                  <>
                    <InputLabel sx={{ marginTop: "20px" }}>Enter text for the new tag type</InputLabel>
                    <TextField fullWidth value={textInput} onChange={(e) => setTextInput(e.target.value)} />
                  </>
                }
                width='600'
                showPositive
                positiveText='Add'
                positiveButtonWidget={
                  showAddCategoryArchiveLoader ? (
                    <Button variant="contained" disabled sx={{ "&.Mui-disabled": { backgroundColor: '#025196', color: '#fff' }, marginInline: '2px', textTransform: 'initial', color: '#025196', backgroundColor: '#025196', ":hover": { backgroundColor: '#025196' } }}>
                      <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Adding...</span>
                    </Button>
                  ) : (
                    <Button variant="contained" sx={{ marginInline: '2px', textTransform: 'initial', backgroundColor: '#025196', ":hover": { backgroundColor: '#025196' } }} onClick={handleAddCategory}>
                      Add
                    </Button>
                  )
                }
              />
            </div>
          </Card>
        </Grid>
      </Grid >
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps;
  return {
    project: state.project,
    drProjectData: get(state.firestore.data, `DrProject.${proid}`),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCategory: (proid, textInput) => dispatch(addCategory(proid, textInput)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarVisualizer);
