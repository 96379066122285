import { Button, Card, Divider, Grid, Typography } from '@mui/material'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux';
import { addBasicImagingDefect, basicImagingValidConfirm, clearAddDefectErrorAndMsg, clearArchiveErrorAndMsg, clearUpdateErrorAndMsg, clearValidConfirmErrorAndMsg, getIndividualDetection, removeAddNewAssetErrorAndMessage, removeGetIndividualDetectionErrorAndMessage, resetArchiveFeature, safetyConfirmDelta, updateBasicImagingDefect } from '../../../../../../store/actions/projectActions';
import { get } from 'lodash';
import PinnedPothole from './PinnedPothole';
import PotholeTable from './PotholeTable';
import DrawBBox from '../../components/DrawBbox';
import ArchivedPotholeViewer from './ArchivedPotholeViewer';
import ClearIcon from '@mui/icons-material/Clear';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import ComparePotholeFields from './ComparePotholeFields';
import PotholeVideoPlayer from './PotholeVideoPlayer';
import PotholeTableFilters from './PotholeTableFilters';
import PotholeFrameList from './PotholeFrameList';
import PotholeMap from './PotholeMap';
import PotholeTopbar from './PotholeTopbar';
import { useAccessControlContext } from '../../../../../../contexts/AccessControlProvider';
import { storage } from '../../../../../../config/firebaseConfig';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import maskImage from '../../../../../../assets/bg/mask.png'
import ImageAnnotatorV3 from '../../surfaceEvaluationComponents/ImageAnnotatorV3';
const PotholeInferenceDashboard = (props) => {
  const { permissions, role } = useAccessControlContext();
  const { canAddDefectBasic, canUnarchiveDefectBasic } = permissions;
  var { datastore, projectPredictions, predictionCropb64List, drProjectData, proid, link, project } = props;
  const { individualDetectionData, showConfirmDeltaLoader, showUpdateDefectLoader, updateDefectDoneAndSent, basicValidConfirmIsDoneAndSent, basicFeatureArchiveIsDoneAndSent, individualDetectionMessage, individualDetectionIsDoneAndSent, showindividualDetectionLoader, showBasicValidConfirmLoader, addDefectDoneAndSent, showAddDefectLoader, addDefectMessage } = props.project;
  const { assetData, dataInfo, basicImagingData } = datastore;
  const { dataInfo: basicImagingDataInfo } = basicImagingData;
  const { showAddNewAssetLoader, addNewAssetMessage } = project;
  const [isCompareOpen, setIsCompareOpen] = useState(null);
  const [defectImageURLs, setDefectImageURLs] = useState([]);
  const [maskImageURLs, setMaskImageURLs] = useState([]);
  const [pinned, setPinned] = useState(null);
  const [toggleImage, setToggleImage] = useState(false)
  const vidDivRef = useRef(null);
  const playerRef = useRef(null);
  const comparePlayerRef = useRef(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
  const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
  const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [qualities, setQualities] = useState([])
  const [openAddNewAssetDialog, setOpenAddNewAssetDialog] = useState(false);
  const [openCompareDialog, setOpenCompareDialog] = useState(false);
  const cardWhiteStyle = { "borderRadius": "15px" }
  const videoHeight = openCompareDialog ? 45 : 50;
  const mapHeight = openCompareDialog ? 45 : 50;
  let assetVideoPlayerPadding;
  const overallCustomBorderPadding = assetVideoPlayerPadding = 16;
  const customMargin = 10;
  const realFps = 'fps' in drProjectData ? drProjectData.fps : 23.976023976023978
  const fps = Number(realFps).toFixed(0)
  const originalVideoHeight = drProjectData?.dimensions?.height ?? 0
  const originalVideoWidth = drProjectData?.dimensions?.width ?? 0
  const [sectionIndex, setSectionIndex] = useState(0);
  const [showMaskImage, setShowMaskImage] = useState(false);
  const roadSideMap = {
    "map": dataInfo['roadSides'],
    "keys": Object.keys(dataInfo['roadSides']),
    "values": Object.values(dataInfo['roadSides']),
    "usefulKeys": ['0', '1'],
  }
  const priorityMap = {
    "map": basicImagingDataInfo['priority'],
    "keys": Object.keys(basicImagingDataInfo['priority']),
    "values": Object.values(basicImagingDataInfo['priority']),
    "usefulKeys": ['0', '1', '2'],
  }
  const classesMap = {
    "map": dataInfo['classes'],
    "keys": Object.keys(dataInfo['classes']),
    "values": Object.values(dataInfo['classes']),
    "usefulKeys": ['0', '1', '2', '3', '4', '998'],
    'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
    "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
    "trafficSign": ['1'],
  }

  projectPredictions = useMemo(() => Object.values(projectPredictions).sort((a, b) => {
    return a.sectionId - b.sectionId
  }), [projectPredictions])
  const predictions = useMemo(() => [projectPredictions[sectionIndex]], [sectionIndex, projectPredictions]);
  // const predictions = projectPredictions;
  let gpsData = []
  let gpsDataCombined = []
  let rawDetections = []
  let originalDetections = []
  const wholeProjectGps = projectPredictions.map((eachSection) =>
    Object.values(eachSection.GPS)
  );
  predictions.forEach((e) => {
    gpsData = [...gpsData, e['GPS']]
    gpsDataCombined = [...gpsDataCombined, ...e['GPS']]
    rawDetections = [...rawDetections, ...Object.values(e['detections'])]
    const localDetections = Object.values(e['detections']).map((eachDetection) => {
      // Create a new object with properties from eachDetection
      const newDetection = {
        ...eachDetection,
        cropb64: eachDetection['id'] in (predictionCropb64List ?? []) ? predictionCropb64List[eachDetection['id']] : '',
        sectionId: e['id']
      };

      // If versioning exists, add its properties to the newDetection object
      if (eachDetection.versioning) {
        newDetection.defectName = eachDetection.versioning.defectName || newDetection.defectName;
        newDetection.priority = eachDetection.versioning.priority || newDetection.priority;
        newDetection.isValid = eachDetection.versioning.isValid || newDetection.isValid;
        newDetection.bbox = eachDetection.versioning.bbox || newDetection.bbox;
      }
      return newDetection;
    });

    originalDetections = [...originalDetections, ...localDetections];
  });
  let detections = originalDetections.filter((eachDetection) => {
    return eachDetection.archived === "0"
  })
  detections.sort((a, b) => {
    return a.frameNumber - b.frameNumber
  })
  const doesGpsDataCombinedExists = gpsDataCombined?.length > 0
  const startEndLocation = {
    startLat: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lat : 0,
    startLng: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lng : 0,
    endLat: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lat : 0,
    endLng: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lng : 0,
  }
  const handleChangeSectionIndex = (event) => {
    setPinned(null);
    const sectionIndex = event.target.value;
    const segmentObj = mapIt(projectPredictions[sectionIndex]);
    setSectionIndex(sectionIndex);
    onSegmentChange(segmentObj);
  }
  function mapIt(sectionDocument) {
    let sortedDetections = Object.values(sectionDocument.detections);
    sortedDetections.sort((x, y) => x.frameTime - y.frameTime);
    if (sortedDetections.length == 0) {
      return {
        id: sectionDocument.id,
        sectionId: sectionDocument.sectionId,
        initTime: -1,
        endTime: -1,
      };
    }
    return {
      id: sectionDocument.id,
      sectionId: sectionDocument.sectionId,
      initTime: sortedDetections[0].frameTime,
      endTime: sortedDetections.at(-1).frameTime,
    };
  }
  useEffect(() => {
    if (pinned) {
      const data = projectPredictions.filter((each) => each.id === pinned.pred.sectionId)[0]['detections'][pinned.pred.id];
      if (data.versioning) {
        data.defectName = data.versioning.defectName || data.defectName;
        data.priority = data.versioning.priority || data.priority;
        data.isValid = data.versioning.isValid || data.isValid;
        data.bbox = data.versioning.bbox || data.bbox;
      }
      setPinned(data)
    }
  }, [projectPredictions])
  useEffect(() => {
    const selectedSection = projectPredictions.map((eachObj) => mapIt(eachObj));
    const pickedSections = selectedSection.filter((eachSegment) =>
      SmartUtils.checkInRange(Number((eachSegment.initTime)).toFixed(5), Number((eachSegment.endTime)).toFixed(5), Number(currentTime).toFixed(5)));
    if (pickedSections.length > 0) {
      const sectionId = pickedSections[0].sectionId;
      setSectionIndex(sectionId)
    }
  }, [currentTime])
  const onSegmentChange = (segmentObj) => {
    playerRef?.current?.pause()
    const startTime = (segmentObj.initTime);
    playerRef?.current?.seek(startTime + 1 / fps)
  }
  const onCurrentTimeChange = (currentTime) => {
    setCurrentTime(Number(currentTime - 1 / fps).toFixed(5))
  }
  const calculateQuality = (score) => {
    // 0: low ,1:med,2:high
    if (score === 0) {
      return 2
    }

    if (score === 1) {
      return 1
    }

    if (score === 2) {
      return 0
    }
  }
  const handleQuality = (index) => {
    setPinned(null);
    if (qualities.includes(index)) {
      setQualities([...qualities].filter((e) => e !== index))
    } else {
      setQualities([...qualities, index])
    }
  }
  const handleOpenAddNewAssetDialog = () => {
    props.removeAddNewAssetErrorAndMessage();
    setOpenAddNewAssetDialog(true);
  };
  const handleCloseAddNewAssetDialog = () => {
    setOpenAddNewAssetDialog(false);
  };
  const getAssetMeta = (assetId, debug = false) => {
    const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
    if (debug) {
      // //////console.log(assetMeta, "getAssetMeta")
    }
    return assetMeta
  }
  const isAssetTrafficSign = (assetClass) => {
    return !classesMap.assetClassesWhereNameWillNotShow.includes(assetClass);
  }
  useEffect(() => {
    if (pinned) {
      setPinned(pinned);
      handleSeekTo(pinned.pred.frameTime);
    }
  }, [openCompareDialog])
  const nextAsset = () => {
    if (assetIndex < timeList.length) {
      assetIndex = assetIndex + 1;
      const nextPred = timeList[assetIndex];
      // const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
      const obj = {
        pred: nextPred,
        // meta: nextMeta
      }
      handlePinned(obj);
    }
  }
  const previousAsset = () => {
    if (assetIndex >= 0) {
      assetIndex = assetIndex - 1;
      const nextPred = timeList[assetIndex];
      // const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
      const obj = {
        pred: nextPred,
        // meta: nextMeta
      }
      handlePinned(obj);
    }
  }
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClickOpenCompareDialog = () => {
    setOpenCompareDialog(true);
  };
  const handleCloseCompareDialog = () => {
    setOpenCompareDialog(false);
    setIsCompareOpen(null);
  };
  const handleNextFrame = () => {
    const secondPerFrame = 1 / fps
    playerRef.current.forward(secondPerFrame)
  }
  const handlePrevFrame = () => {
    const secondPerFrame = 1 / fps
    playerRef.current.replay(secondPerFrame)
  }
  const handlePinned = (data) => {
    ;
    //////console.log(data, "data---")
    const check = data === null ? false : pinned === null ? false : data.pred.id === pinned.pred.id
    playerRef.current?.pause()
    setPinned(check ? null : data)
    if (data) {
      handleSeekTo(data.pred.frameTime)
      // if (!openCompareDialog) {
      //   handleSeekTo(data.pred.frameTime)
      // }
    }
  }
  const getVidWindowSize = () => {
    const newHeight = vidDivRef?.current?.clientHeight;
    const newWidth = vidDivRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth
    });
  };
  const handleSeekTo = (timeSeek) => {
    playerRef?.current?.seek(timeSeek + (1 / fps))
  }
  const handleCallCompareDataApi = (outputId) => {
    const { proid } = props;
    props.removeGetIndividualDetectionErrorAndMessage();
    props.getIndividualDetection(proid, outputId);
  }
  const dataset = assetData;
  const imageBase64 = dataset.filter((e) => `${e.id}`.split('_')[0] === `${pinned?.meta?.id ?? ''}`.split('_')[0])[0]?.base64;
  const cropBase64 = `data:image/png;base64, ${pinned?.pred?.cropb64 ?? ''}`;
  const timeList = useMemo(() => {
    return qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(e.priority)));
  }, [projectPredictions, qualities, sectionIndex]);
  const index = (element) => element.id === pinned?.pred?.id;
  var assetIndex = timeList.findIndex(index);

  useEffect(() => {
    const generateDefectAndMaskImageURLs = async () => {
      const defectUrls = {};
      const maskUrls = {};

      await Promise.all(timeList.map(async (defect) => {
        const { id, defectImagePath, maskPath } = defect;

        try {
          // Fetch defect image URL
          const defectStorageRef = storage.ref(defectImagePath);
          const defectDownloadURL = await defectStorageRef.getDownloadURL();
          defectUrls[id] = defectDownloadURL;
        } catch (error) {
          console.error(`Error fetching defect image URL for defect with ID ${id}:`, error);
          defectUrls[id] = null;
        }

        try {
          // Fetch mask image URL
          const maskStorageRef = storage.ref(maskPath);
          const maskDownloadURL = await maskStorageRef.getDownloadURL();
          maskUrls[id] = maskDownloadURL;
        } catch (error) {
          console.error(`Error fetching mask image URL for defect with ID ${id}:`, error);
          maskUrls[id] = null;
        }
      }));

      setDefectImageURLs(defectUrls);
      setMaskImageURLs(maskUrls);
    };

    generateDefectAndMaskImageURLs();
  }, [timeList]);
  useEffect(() => {
    if (showAddNewAssetLoader === false) {
      const data = addNewAssetMessage;
      var obj = null;
      if (data) {
        const assetMeta = getAssetMeta(data.assetId)
        obj = {
          pred: data,
          meta: assetMeta
        }
      }
      handlePinned(obj)
      setOpenAddNewAssetDialog(false);
    }
  }, [showAddNewAssetLoader])
  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(vidDivRef?.current?.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, [openCompareDialog])
  useEffect(() => {
    const divHeight = vidDivRef?.current?.clientHeight
    const divWidth = vidDivRef?.current?.clientWidth - 0;
    const imageHeight = originalVideoHeight//playerRef?.current?.videoHeight//imageDimensions.height;
    const imageWidth = originalVideoWidth//playerRef?.current?.videoWidth//imageDimensions.width;
    const asDiv = (divHeight / divWidth)
    const asImg = (imageHeight / imageWidth)
    const ratio = asDiv / asImg
    let svgWidth = 0
    let svgHeight = 0
    if (ratio >= 1) {
      svgWidth = divWidth
      svgHeight = asImg * divWidth
    } else {
      svgHeight = divHeight
      svgWidth = (1 / asImg) * divHeight
    }
    // //////console.log("Width:", divWidth, imageWidth, svgWidth)
    // //////console.log("Height:", divHeight, imageHeight, svgHeight)
    // //////console.log("Top:", (divHeight - svgHeight) / 2, "left:", (divWidth - svgWidth) / 2)
    setSvgDimensions({
      width: svgWidth,
      height: svgHeight,
      scale: imageHeight / svgHeight,
      offsetTop: (divHeight - svgHeight) / 2,
      offsetLeft: (divWidth - svgWidth) / 2,
    })
  }, [imageDimensions])
  useEffect(() => {
    // loadImage(setImageDimensions, sampleImg);
    setImageDimensions({
      height: originalVideoHeight,//playerRef?.current?.videoHeight,
      width: originalVideoWidth,//playerRef?.current?.videoWidth,
    })
  }, [pinned]);
  useEffect(() => {
    // loadImage(setImageDimensions, sampleImg);
    setImageDimensions({
      height: originalVideoHeight,//playerRef?.current?.videoHeight,
      width: originalVideoWidth,//playerRef?.current?.videoWidth,
    })
  }, [vidDivDimen])
  useEffect(() => {
    if (pinned) {
      let newPred = Object.values(projectPredictions).filter((e) => e.id === pinned.pred.sectionId)[0]['detections'][pinned.pred.id];
      // Make sure newPred is extensible
      newPred = { ...newPred };
      newPred['cropb64'] = predictionCropb64List ? predictionCropb64List[newPred['id']] ? predictionCropb64List[newPred['id']] : '' : '';
      newPred['sectionId'] = pinned.pred.sectionId;
      newPred['roadSide'] = 'versioning' in newPred ? newPred['versioning']['roadSide'] : newPred['roadSide'];
      const assetId = 'versioning' in newPred ? newPred['versioning']['assetId'] : newPred['assetId'];
      const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
      const obj = {
        pred: newPred,
        meta: assetMeta
      }
      setPinned(obj);
    }
  }, [projectPredictions])

  useEffect(() => {
    if (addDefectDoneAndSent === true) {
      handleCloseAddNewAssetDialog();
      props.clearAddDefectErrorAndMsg();
    }
  }, [addDefectDoneAndSent])
  const isTraficSignClass = isAssetTrafficSign(pinned?.meta?.class);
  const headerRef = useRef(null)
  const headerHeight = headerRef?.current?.clientHeight ?? 0
  const pinnedUiHeight = pinned === null ? 0 : 45;
  const tableHeight = 90 - pinnedUiHeight;
  const isCurrentFramePinned = true;
  const isVideoActive = false;
  const compareWindowMargin = openCompareDialog ? overallCustomBorderPadding : 0;
  return (
    <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>
      <Grid container spacing={0}>
        <Grid item xs={7} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)`, padding: '' }}>
          {/* Topbar */}
          <div ref={headerRef} style={{ padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, backgroundColor: '' }}>
            <PotholeTopbar handleChangeSectionIndex={handleChangeSectionIndex} sectionIndex={sectionIndex} predictions={projectPredictions} drProjectData={drProjectData} basicImagingDataInfo={basicImagingDataInfo} startEndLocation={startEndLocation} proid={proid} detections={detections} assetData={assetData} dataInfo={dataInfo} />
          </div>
          {/* pinned window */}
          <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
            <div style={{ height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`, paddingLeft: `${overallCustomBorderPadding}px`, paddingBottom: `${overallCustomBorderPadding}px`, backgroundColor: '' }}>
              <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)` }}>

                <div style={{ height: `${pinnedUiHeight}%`, backgroundColor: '', }}>
                  {
                    pinned === null ? <></> :
                      <PinnedPothole
                        defectImageURLs={defectImageURLs}
                        basicFeatureArchiveIsDoneAndSent={basicFeatureArchiveIsDoneAndSent}
                        clearUpdateErrorAndMsg={props.clearUpdateErrorAndMsg}
                        clearArchiveErrorAndMsg={props.clearArchiveErrorAndMsg}
                        drProjectData={drProjectData}
                        updateDefectDoneAndSent={updateDefectDoneAndSent}
                        basicValidConfirmIsDoneAndSent={basicValidConfirmIsDoneAndSent}
                        clearValidConfirmErrorAndMsg={props.clearValidConfirmErrorAndMsg}
                        showConfirmDeltaLoader={showConfirmDeltaLoader}
                        showUpdateDefectLoader={showUpdateDefectLoader}
                        showBasicValidConfirmLoader={showBasicValidConfirmLoader}
                        safetyConfirmDelta={props.safetyConfirmDelta}
                        updateBasicImagingDefect={props.updateBasicImagingDefect}
                        basicImagingValidConfirm={props.basicImagingValidConfirm}
                        resetArchiveFeature={props.resetArchiveFeature}
                        basicImagingDataInfo={basicImagingDataInfo}
                        handleCallCompareDataApi={handleCallCompareDataApi}
                        setIsCompareOpen={setIsCompareOpen} handleClickOpenCompareDialog={handleClickOpenCompareDialog}
                        dataInfo={dataInfo} originalVideoHeight={originalVideoHeight}
                        originalVideoWidth={originalVideoWidth} playerRef={playerRef} proid={proid} qualities={qualities} handleQuality={handleQuality} timeList={timeList} handleClickOpenDialog={handleClickOpenDialog} openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                        previousAsset={previousAsset} assetIndex={assetIndex} nextAsset={nextAsset}
                        handlePinned={handlePinned} fps={fps} realFps={realFps} link={link} pinned={pinned} gpsData={gpsData} gpsDataCombined={gpsDataCombined}
                        classesMap={classesMap} isTraficSignClass={isTraficSignClass} roadSideMap={roadSideMap} imageBase64={imageBase64} cropBase64={cropBase64}
                      />
                  }
                </div>

                <div style={{ height: `${tableHeight}%`, backgroundColor: '', }}>
                  <PotholeTable sectionIndex={sectionIndex} defectImageURLs={defectImageURLs} basicImagingData={basicImagingData}
                    openCompareDialog={openCompareDialog} handleCallCompareDataApi={handleCallCompareDataApi}
                    isCompareOpen={isCompareOpen} setIsCompareOpen={setIsCompareOpen}
                    handleClickOpenCompareDialog={handleClickOpenCompareDialog}
                    disableHeader={false} fps={fps} playerRef={playerRef} pinned={pinned}
                    dataList={timeList} handlePinned={handlePinned} assetData={dataset}
                    dataInfo={dataInfo}
                    priorityMap={priorityMap}
                  />
                </div>

                <div style={{ height: `10%`, backgroundColor: '', }}>
                  <Divider light />
                  <Grid container>
                    <Grid item xs={7}>
                      <PotholeTableFilters qualities={qualities} handleQuality={handleQuality} />
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      <Button disabled={!canAddDefectBasic} onClick={openCompareDialog ? null : handleOpenAddNewAssetDialog} sx={{ marginRight: '10px', textTransform: 'none', textWrap: 'nowrap', color: openCompareDialog ? '#B3B3B3' : '#1976D2', fontWeight: '700' }}>+ Add new feature</Button>
                      {canUnarchiveDefectBasic ? <ArchivedPotholeViewer basicImagingData={basicImagingData} assetData={assetData} detections={originalDetections} proid={proid} /> : <></>}
                      {pinned ? <Button onClick={() => { setShowMaskImage(!showMaskImage) }} sx={{ marginRight: '10px', textTransform: 'none', textWrap: 'nowrap', color: openCompareDialog ? '#B3B3B3' : '#1976D2', fontWeight: '700' }}>{showMaskImage ? "Show map" : "Show mask image"}</Button> : <></>}
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </div>
          </div>
        </Grid>
        <Grid item xs={5} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)` }}>
          <div style={{ margin: openCompareDialog ? `${overallCustomBorderPadding}px` : '0px', backgroundColor: 'transparent', height: `calc(100vh - ${0}px - ${compareWindowMargin * 2}px)` }}>{

            <Card elevation={openCompareDialog ? 0 : 0} sx={{ ...cardWhiteStyle, backgroundColor: openCompareDialog ? '#FFFFFF' : '#E1E3E2', height: `calc(100vh -  ${compareWindowMargin * 2}px)`, }}>
              {openCompareDialog ?
                <div style={{ height: `calc(${10}vh - ${0 * 2}px - ${compareWindowMargin * 2}px))`, background: 'transparent' }}>
                  <Grid container spacing={0} sx={{ padding: `${assetVideoPlayerPadding}px`, display: 'flex', alignItems: 'center', height: `calc(${10}vh - ${0 * 2}px - ${0 * 2}px)`, background: '' }}>
                    <Grid xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant='h6'>Previous record</Typography>
                    </Grid>
                    <Grid xs={9} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '20px' }}>
                      <>
                        <ClearIcon
                          sx={{
                            color: "#1E1E1E",
                            // marginLeft: "20px",
                            cursor: "pointer",
                          }}
                          onClick={handleCloseCompareDialog}
                        />
                      </>
                    </Grid>
                  </Grid>
                  <Divider light />
                </div>
                : <></>}
              {/* Video player */}
              <div id='asset-player' style={{ padding: `${assetVideoPlayerPadding}px`, height: `calc(${videoHeight}vh - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                <Card elevation={openCompareDialog ? 0 : 0} sx={{ ...cardWhiteStyle, height: `calc(${videoHeight}vh - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                  {/* AssetFrameList */}
                  {openCompareDialog ? <></> : <div style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px - ${0 * 2}px )`, backgroundColor: 'transparent', width: '12%', float: 'left', padding: '10px' }}>
                    <PotholeFrameList defectImageURLs={defectImageURLs} basicImagingData={basicImagingData} handlePinned={handlePinned} playerRef={playerRef} detections={timeList} pinned={pinned} fps={fps} />
                  </div>}
                  {/* AssetVideoPlayer */}
                  <div ref={vidDivRef} style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px - ${0 * 2}px)`, backgroundColor: 'transparent', width: openCompareDialog ? '100%' : '88%', float: 'right' }}>
                    <div className="container" style={{ padding: '0px' }}>
                      <div className="inner" style={{ zIndex: 997 }}>
                        {openCompareDialog ?
                          individualDetectionData ?
                            <PotholeVideoPlayer projectPredictions={projectPredictions} fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={comparePlayerRef}
                              link={link} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} disableControlsFully={true} startTime={individualDetectionData.frameTime} shouldOffset={true} />
                            : <CustomProgress text={"Loading"} />
                          :
                          <PotholeVideoPlayer
                            projectPredictions={projectPredictions}
                            onCurrentTimeChange={onCurrentTimeChange}
                            clearAddDefectErrorAndMsg={props.clearAddDefectErrorAndMsg}
                            showAddDefectLoader={showAddDefectLoader} addDefectDoneAndSent={addDefectDoneAndSent}
                            addDefectMessage={addDefectMessage}
                            addBasicImagingDefect={props.addBasicImagingDefect}
                            fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                            originalVideoHeight={originalVideoHeight}
                            originalVideoWidth={originalVideoWidth}
                            allPolyPoints={gpsDataCombined}
                            proid={proid}
                            basicImagingDataInfo={basicImagingDataInfo}
                            openAddNewAssetDialog={openAddNewAssetDialog} setOpenAddNewAssetDialog={setOpenAddNewAssetDialog} handleCloseAddNewAssetDialog={handleCloseAddNewAssetDialog}
                            link={link} pinned={pinned} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight}
                            startTime={1 / fps} control={toggleImage || pinned} disableControlsFully={toggleImage || pinned} />
                        }
                      </div>
                      {pinned || openCompareDialog ?
                        <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                          <div style={{
                            height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                            justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                          }}>
                            <svg height={svgDimensions.height} width={svgDimensions.width}

                              style={{
                                backgroundColor: 'rgba(34,55,245, 0.0)',

                              }}
                            >
                              {/* {isVideoActive ? <></> : pinned !== null ? isCurrentFramePinned ? getRectWh([...pinned?.pred?.bbox].splice(0, 2), [...pinned?.pred?.bbox].splice(2, 4), true, true) : <></> : <></>} */}

                              {
                                openCompareDialog ?
                                  individualDetectionData ?
                                    <DrawBBox xy={[...individualDetectionData.bbox].splice(0, 2)} wh={[...individualDetectionData.bbox].splice(2, 4)} shouldScale scale={svgDimensions.scale} />
                                    : <></>
                                  :
                                  isVideoActive ? <></> : pinned !== null ? isCurrentFramePinned ? <DrawBBox xy={[...pinned?.pred?.bbox].splice(0, 2)} wh={[...pinned?.pred?.bbox].splice(2, 4)} shouldScale scale={svgDimensions.scale} /> : <></> : <></>}
                            </svg>
                          </div>
                        </div> : <></>}
                    </div>
                  </div>
                </Card>
              </div>
              {/* Asset map */}
              <div id='asset-map'
                style={{
                  padding: `0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px 
              ${overallCustomBorderPadding}px`,
                  height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px))`, backgroundColor: 'transparent'
                }}>

                {openCompareDialog ?
                  <div style={{ height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px)` }}>
                    <ComparePotholeFields basicImagingDataInfo={basicImagingDataInfo} height={`calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px)`} individualDetectionData={individualDetectionData} dataInfo={dataInfo} assetData={assetData} />
                  </div>
                  :
                  <Card elevation={4} sx={{ ...cardWhiteStyle, background: 'white' }}>
                    <div style={{ height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px)` }}>
                      {showMaskImage && pinned ?
                        <img src={maskImageURLs[pinned.pred.id] ?? maskImage} width="100%" style={{ objectFit: 'contain' }} /> : gpsDataCombined.length > 0 ?
                          <PotholeMap basicImagingData={basicImagingData} fps={fps} playerRef={playerRef} zoom={15} dataList={timeList} assetData={dataset} polyPoints={[wholeProjectGps]} allPolyPoints={gpsDataCombined} center={gpsDataCombined[-1]} pinned={pinned} handleSeekTo={handleSeekTo} handlePinned={handlePinned} />
                          : <></>}
                    </div>
                  </Card>
                }

              </div>
            </Card>
          }

          </div>
        </Grid >
      </Grid >
    </div >
  )
}
const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps
  return {
    project: state.project,
    datastore: state.datastore,
    drProjectData: get(
      state.firestore.data,
      `DrProject.${proid}`
    ),

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeAddNewAssetErrorAndMessage: () => dispatch(removeAddNewAssetErrorAndMessage()),
    getIndividualDetection: (proid, outputId) => dispatch(getIndividualDetection(proid, outputId)),
    removeGetIndividualDetectionErrorAndMessage: () => dispatch(removeGetIndividualDetectionErrorAndMessage()),
    resetArchiveFeature: () => dispatch(resetArchiveFeature()),
    safetyConfirmDelta: (proid, sectionId, detectionId) => dispatch(safetyConfirmDelta(proid, sectionId, detectionId)),
    basicImagingValidConfirm: (proid, sectionId, detectionId) => dispatch(basicImagingValidConfirm(proid, sectionId, detectionId)),
    updateBasicImagingDefect: (proid, sectionId, detectionId, priority, defectType, validity, bbox) => dispatch(updateBasicImagingDefect(proid, sectionId, detectionId, priority, defectType, validity, bbox)),
    clearUpdateErrorAndMsg: () => dispatch(clearUpdateErrorAndMsg()),
    clearArchiveErrorAndMsg: () => dispatch(clearArchiveErrorAndMsg()),
    clearValidConfirmErrorAndMsg: () => dispatch(clearValidConfirmErrorAndMsg()),
    clearAddDefectErrorAndMsg: () => dispatch(clearAddDefectErrorAndMsg()),
    addBasicImagingDefect: (proid, sectionId, priority, defectType, bbox, gps, frameNumber) => dispatch(addBasicImagingDefect(proid, sectionId, priority, defectType, bbox, gps, frameNumber)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PotholeInferenceDashboard);