import { Card, Grid, IconButton, Tab, Tabs } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SurfaceEvaluationTopbar from './surfaceEvaluationComponents/SurfaceEvaluationTopbar';
import CustomProgress from '../../../cidgets/CustomProgress';
import SmartUtils from '../../../../helpers/SmartUtils';
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useGenerateSignedUrl } from '../../../../chooks/useGenerateSignedUrl';
import SurfaceEvaluationVideoPlayer from './subpages/afterInference/SurfaceEvaluationVideoPlayer';
import { useNavigate } from 'react-router-dom';
import DistressMap from './surfaceEvaluationComponents/DistressMap';
import { connect } from 'react-redux';
import { analysisSetting, depthCrossSectionalProfile, getDownSamplingData, saveDepthCrossSectionalPoints } from '../../../../store/actions/projectActions';
import SegmentShifterV2 from './surfaceEvaluationComponents/SegmentShifterV2';
const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 'auto',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.2)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
  },
  activeTab: {
    color: "white",
    backgroundColor: "#025196",
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderTop: '3px solid #041256',
    borderRight: '3px solid #041256',
    borderLeft: '3px solid #041256',
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: "#025196",
    }
  },

}));
const AfterSurfaceEvaluationInferenceGroupDashboard = (props) => {
  // startendlocation 
  const { drProjectData, distressProjectPredictions, secId, proid } = props;
  const { dimensions } = drProjectData;
  const { height, width } = dimensions;

  const [filteredGpsData, setFilteredGpsData] = useState(Object.values(distressProjectPredictions));
  const [selectedDefects, setSelectedDefects] = useState(drProjectData.selectedDefects ? drProjectData.selectedDefects : []);
  const [conditionMethod, setConditionMethod] = useState(
    drProjectData.conditionMethod
      ? drProjectData.conditionMethod
      : drProjectData.projectType === 4
        ? (drProjectData.conditionIndex ? drProjectData.conditionIndex[0] : -1)
        : (drProjectData.conditionIndex ? drProjectData.conditionIndex[0] : 0)
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sectionLength, setSectionLength] = useState(10);
  const [segmentIndex, setSegmentIndex] = useState(0);
  const [newCurrentPage, setNewCurrentPage] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isWholeDistressVisualization, setIsWholeDistressVisualization] = useState(false);
  const [distressPinned, setDistressPinned] = useState(null);
  const [crossSectionalPoints, setCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);
  const [defaultCrossSectionalPoints, setDefaultCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);
  const [sections, setSections] = React.useState(0);
  const [pinned, setPinned] = useState(null);
  const [isDepthCrossSectional, setIsDepthCrossSectional] = useState(false);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });

  const headerRef = useRef();
  const tabRef = useRef();
  const videoRef = useRef();
  const playerRef = useRef(null);

  const classes = useStyles();
  let navigate = useNavigate();
  const RATE = 0.1;
  let gpsDataCombined = [];
  let detectionsList = [];
  let predictions = Object.values(distressProjectPredictions);
  const realFps = 'fps' in drProjectData ? drProjectData.fps : 59.94005994005994
  const FPS = Number(realFps).toFixed(0);
  const headerHeight = headerRef?.current?.clientHeight ?? 0;
  const tabHeight = tabRef?.current?.clientHeight ?? 0;
  const su = new SmartUtils();
  const overallCustomBorderPadding = 16;
  const cardWhiteStyle = { "borderRadius": "15px", };
  const videoSectionHeight = 50;
  const mapSectionHeight = 50;
  const defectNames = SmartUtils.getDefectNamesList(drProjectData.projectType);

  const videosPerPage = 4;
  const videosList = Object.values(drProjectData?.groupVideosInfo);
  videosList.sort((a, b) => a.groupId - b.groupId);
  const [selectVideo, setSelectVideo] = useState(videosList[0])
  const totalPages = Math.ceil(videosList?.length / videosPerPage);
  const currentVideos = videosList.slice(
    newCurrentPage * videosPerPage,
    (newCurrentPage + 1) * videosPerPage
  );
  const isGroupData = drProjectData.groupVideosInfo && drProjectData.groupVideosInfo.length > 1 ? true : false;
  const tabs = Object.values(currentVideos).map((path, index) => (
    <Tab
      key={index}
      value={index + newCurrentPage * videosPerPage}
      label={`Video ${(index + newCurrentPage * videosPerPage) + 1}`}
      className={`${classes.tab} ${tabValue === (index + newCurrentPage * videosPerPage) ? classes.activeTab : ''}`}
      // className={`${classes.tab} ${segmentIndex === (index + newCurrentPage * videosPerPage) ? classes.activeTab : ''}`}
      sx={{ width: "25%", textTransform: 'none', fontWeight: 'bold', fontSize: '16px' }}
    />
  ));

  const { status, error, data } = useGenerateSignedUrl(
    selectVideo ? selectVideo.path : "", false
  );
  const isFetched = status === 'fetched';
  // const isFetched = false;
  filteredGpsData.sort((a, b) => {
    return a.groupEndIndex - b.groupEndIndex
  });
  Object.values(filteredGpsData).forEach((section) => {
    detectionsList = [...detectionsList, Object.values(section.detections)];
    Object.values(section.detections).forEach((eachData) => {
      gpsDataCombined = [...gpsDataCombined, { sectionId: section.sectionId, lat: eachData.lat, lng: eachData.lng, time: Date.now(), defectName: eachData.defectName, geoTime: (eachData.groupFrameNumber / FPS).toFixed(5) }]
    })
  });
  const doesGpsDataCombinedExists = gpsDataCombined?.length > 0
  const startEndLocation = {
    startLat: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lat : 0,
    startLng: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lng : 0,
    endLat: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lat : 0,
    endLng: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lng : 0,
  }
  const getVidWindowSize = () => {
    const newHeight = videoRef?.current?.clientHeight;
    const newWidth = videoRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth
    });
  };
  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(videoRef?.current === null ? 0 : videoRef?.current?.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, []);
  useEffect(() => {
    props.getDownSamplingData(proid);
  }, []);
  useEffect(() => {
    props.analysisSetting(selectedDefects, conditionMethod, proid);
  }, [selectedDefects, conditionMethod]);
  useEffect(() => {
    let predictionsArray = Object.values(distressProjectPredictions);
    predictionsArray = predictionsArray.filter((eachSection) => eachSection.groupId === selectVideo.groupId);
    setFilteredGpsData(predictionsArray);
    if (selectedDefects.length > 0) {
      handleGpsDataFilterChange({ target: { value: selectedDefects } });
    }
  }, [distressProjectPredictions, selectVideo]);

  const handleGpsDataFilterChange = (event) => {
    const selectedDefectIds = event.target.value;
    setSelectedDefects(selectedDefectIds);
    if (selectedDefectIds.length > 0) {
      const finalFilteredData = Object.values(distressProjectPredictions).filter((eachSection) => eachSection.groupId === selectVideo.groupId).map((section) => {
        const filteredDetections = Object.fromEntries(
          Object.entries(section.detections).filter(
            ([key, value]) => selectedDefectIds.includes(value?.versioning?.defectName ?? value.defectName)
          )
        );
        return {
          ...section,
          detections: filteredDetections,
        };
      });
      setFilteredGpsData(finalFilteredData);
    }
    else {
      setFilteredGpsData(Object.values(distressProjectPredictions).filter((eachSection) => eachSection.groupId === selectVideo.groupId));
    }
    setIsFilterOpen(true);
  }
  const getSelectedDefectNames = () => {
    return defectNames
      .filter((defect) => selectedDefects.includes(defect.defectId))
      .map((defect) => defect.name);
  };
  const handlePreviousPage = () => {
    if (newCurrentPage > 0) {
      setNewCurrentPage(newCurrentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (newCurrentPage < totalPages - 1) {
      setNewCurrentPage(newCurrentPage + 1);
    }
  };
  const handleTabChange = (event, newValue) => {
    if (videosList.length > newValue) {
      setTabValue(newValue);
      setSelectVideo(videosList[newValue]);
      setSegmentIndex(0)
      setCurrentTime(0);
      setSections(0);
      navigate(`/home/pr/${proid}/${0}`);
    }
  }
  const handleNextFrame = () => {
    playerRef?.current?.forward(1 / FPS)
  }
  const handlePrevFrame = () => {
    playerRef?.current?.replay(1 / FPS)
  }
  const onCurrentTimeChange = (currentTime) => {
    setCurrentTime(Number(currentTime - 1 / FPS).toFixed(5))
  }
  const handleSectionChangeMap = (sectionIndex) => {
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters = currentSection.crossSectionalParameters ?? false;
    const sectionId = currentSection['sectionId'];

    if (crossSectionalParameters) {
      const points = [
        Number(crossSectionalParameters['x1']),
        Number(crossSectionalParameters['y1']),
        Number(crossSectionalParameters['x2']),
        Number(crossSectionalParameters['y2'])
      ];
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile(
        {
          x1: Number(crossSectionalParameters['x1']),
          y1: Number(crossSectionalParameters['y1']),
          x2: Number(crossSectionalParameters['x2']),
          y2: Number(crossSectionalParameters['y2'])
        },
        proid,
        sectionId
      );
    } else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]),
        y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]),
        y2: Number(defaultCrossSectionalPoints[3])
      };
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };
  const handleSectionChange = (event) => {
    const sectionIndex = event.target.value;
    console.log(sectionIndex, "sectionIndex")
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    // //////console.log(sectionIndex, 'sectionIndex12')
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters = currentSection.crossSectionalParameters ?? false;
    const sectionId = currentSection['sectionId'];

    if (crossSectionalParameters) {
      const points = [Number(crossSectionalParameters['x1']), Number(crossSectionalParameters['y1']), Number(crossSectionalParameters['x2']), Number(crossSectionalParameters['y2'])]
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile({
        x1: Number(crossSectionalParameters['x1']), y1: Number(crossSectionalParameters['y1']), x2: Number(crossSectionalParameters['x2']), y2: Number(crossSectionalParameters['y2'])
      }, proid, sectionId);
    }
    else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]), y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]), y2: Number(defaultCrossSectionalPoints[3])
      }
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };
  const handlePinned = (data) => {
    const check = data === null ? false : pinned === null ? false : data.sectionId === pinned.sectionId
    playerRef.current?.pause()
    if (data) {
      handleSeekTo((data.groupInitIndex) / FPS)
    }
    // setPinned(check ? null : data)
  }
  const onSegmentChange = (segmentObj) => {
    playerRef?.current?.pause()
    const startTime = (segmentObj.groupInitIndex) / FPS;
    playerRef?.current?.seek(startTime + 1 / FPS)
  }
  const handleSeekTo = (timeSeek) => {
    console.log(timeSeek, "timeSeekTo")
    playerRef?.current?.seek(timeSeek)

  }
  const handleDataVisualizationChange = (event) => {
    setIsWholeDistressVisualization(event.target.checked);
  };
  const handleDepthCrossSectional = () => {
    setIsDepthCrossSectional(true);
  }
  const handleCloseDepthCrossSectional = () => {
    setIsDepthCrossSectional(false);
  }
  return (
    <div style={{
      height: '100vh', width: '100vw',
      backgroundColor: '#E1E3E2',
    }}>
      <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
        <Grid item xs={4} sm={4} md={5} lg={6} xl={6} sx={{ backgroundColor: '' }}>
          {/* heaader */}
          <div ref={headerRef} style={{ backgroundColor: '' }}>
            <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
              <SurfaceEvaluationTopbar filteredGpsData={filteredGpsData} isFilterOpen={isFilterOpen}
                selectedDefects={selectedDefects} handleGpsDataFilterChange={handleGpsDataFilterChange}
                getSelectedDefectNames={getSelectedDefectNames} defectNames={defectNames}
                startEndLocation={startEndLocation} proid={proid} drProjectData={drProjectData}
                distressProjectPredictions={filteredGpsData} conditionMethod={conditionMethod}
                setConditionMethod={setConditionMethod} sectionLength={sectionLength}
                setSectionLength={setSectionLength} />
            </div>
          </div>
          <div ref={tabRef} style={{ backgroundColor: '' }}>
            <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
              <div
                style={{
                  display: 'flex', alignItems: 'center',
                  backgroundColor: '#EFEFEF',
                  boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                  width: '100%',
                  borderBottomLeftRadius: '0',
                  borderBottomRightRadius: '0',
                  borderRadius: '15px', paddingInline: '10px', paddingBlock: '2px'
                }}>
                <IconButton onClick={handlePreviousPage} disabled={newCurrentPage === 0}>
                  <ArrowBackIosIcon />
                </IconButton >

                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{ width: '100%' }}
                  indicatorColor=''
                  textColor=''
                >
                  {tabs}
                </Tabs>

                <div style={{ marginLeft: 'auto' }}>
                  <IconButton onClick={handleNextPage} disabled={newCurrentPage === totalPages - 1} >
                    <ArrowForwardIosIcon />
                  </IconButton >
                </div>
              </div>
            </div>
          </div>
          {/* video player & map */}
          {headerHeight === 0 || tabRef === 0 ? <CustomProgress /> :
            <div style={{ height: `calc(100vh - ${headerHeight}px - ${tabHeight}px)`, backgroundColor: '' }}>
              <div style={{ height: `${videoSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>

                <div ref={videoRef} style={{ height: '100%', backgroundColor: '' }}>
                  <Card sx={{ ...cardWhiteStyle }}>
                    {isFetched ? <SurfaceEvaluationVideoPlayer
                      onCurrentTimeChange={onCurrentTimeChange}
                      playerRef={playerRef} defaultRate={RATE}
                      startTime={filteredGpsData.length > 0 ? filteredGpsData[Number(secId ?? 0)] ? filteredGpsData[Number(secId ?? 0)]['groupInitIndex'] / FPS :
                        filteredGpsData[0]['groupInitIndex'] / FPS : 0}
                      fps={FPS} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} link={data}
                      height={videoRef.current === null ? 0 : videoRef.current?.clientHeight}
                      control={false} /> : <div style={{ height: `100%`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading video"} /></div>}
                  </Card>
                </div>

              </div>
              <div style={{ height: `${mapSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'rbl') }}>
                <div style={{ height: '100%', backgroundColor: '' }}>
                  <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                    {isFetched ? <DistressMap isWholeDistressVisualization={isWholeDistressVisualization} distressPinned={distressPinned} setDistressPinned={setDistressPinned}
                      drProjectData={drProjectData} predictions={predictions}
                      segmentIndex={segmentIndex} FPS={FPS}
                      distressProjectPredictions={filteredGpsData}
                      selectVideo={selectVideo}
                      isGroupData={isGroupData}
                      zoom={18}
                      handleSectionChangeMap={handleSectionChangeMap}
                      pinned={pinned} handleSeekTo={handleSeekTo}
                      handlePinned={handlePinned}
                      conditionMethod={conditionMethod} /> : <div style={{ height: `100%`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading map"} /></div>}
                  </Card>
                </div>
              </div>
            </div>
          }
        </Grid>
        <Grid item xs={8} sm={8} md={7} lg={6} xl={6} sx={{ backgroundColor: '' }}>
          <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: '100%' }}>
            <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: '100%' }}>
              {isFetched ? <SegmentShifterV2
                isGroupData={isGroupData}
                selectVideo={selectVideo}
                handleSeekTo={handleSeekTo}
                isWholeDistressVisualization={isWholeDistressVisualization}
                handleDataVisualizationChange={handleDataVisualizationChange}
                distressPinned={distressPinned}
                setDistressPinned={setDistressPinned}
                setPinned={setPinned}
                isDepthCrossSectional={isDepthCrossSectional}
                handleDepthCrossSectional={handleDepthCrossSectional}
                handleCloseDepthCrossSectional={handleCloseDepthCrossSectional}
                segmentIndex={segmentIndex}
                setSegmentIndex={setSegmentIndex}
                predictions={filteredGpsData}
                pinned={pinned}
                originalVideoHeight={height}
                originalVideoWidth={width}
                link={data}
                FPS={FPS}
                playerRef={playerRef}
                proid={proid}
                drProjectData={drProjectData}
                handleSectionChange={handleSectionChange}
                sections={sections}
                setSections={setSections}
                crossSectionalPoints={crossSectionalPoints}
                setCrossSectionalPoints={setCrossSectionalPoints}
                defaultCrossSectionalPoints={defaultCrossSectionalPoints}
                currentTime={currentTime}
                overallCustomBorderPadding={overallCustomBorderPadding}
                conditionMethod={conditionMethod} /> : <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading data"} /></div>}
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    project: state.project,
    datastore: state.datastore,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    depthCrossSectionalProfile: (points, proid, sectionId) => dispatch(depthCrossSectionalProfile(points, proid, sectionId)),
    saveDepthCrossSectionalPoints: (points, proid, sectionId) => dispatch(saveDepthCrossSectionalPoints(points, proid, sectionId)),
    getDownSamplingData: (proid) => dispatch(getDownSamplingData(proid)),
    analysisSetting: (selectedDefects, conditionMethod, proid) => dispatch(analysisSetting(selectedDefects, conditionMethod, proid)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterSurfaceEvaluationInferenceGroupDashboard);
